import React from 'react';
import styled, {keyframes} from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { Section, Container } from '@components/global';

const YoutubeDiv = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 0; overflow: hidden;
  max-width: 100%;
  iframe, object, embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const About = () => (
  <StaticQuery
    query={graphql`
      query {
        art_fast: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "fast" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        art_learn: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "learn_yourself" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        art_ideas: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "ideas" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        art_team: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "team_work" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <div style={{textAlign: 'justify'}}>
        <Section id="about us" style={{marginBottom: -30, paddingBottom: 90}}>
          <div style={{height: 80}}></div>
          <Container style={{textAlign: 'center'}}>
            <h1 style={{textAlign: 'center'}}>About Us</h1>
            <br />
            <p><b>Admission Rush</b> is a boutique International Education Consultancy where students are mentored only by Globally Certified Education Consultants. We have expertise in placing Undergraduate and Graduate school aspirants to some of the world’s finest universities. As an organization, we get immense satisfaction in finding our students, a school they are proud to attend, and where their families are happy to invest.</p>
            <br /><br />
            <YoutubeDiv><iframe src='https://www.youtube.com/embed/SMyUf754cnc' frameborder='0' allowfullscreen /></YoutubeDiv>
          </Container>
        </Section>
        <Section id="our approach" accent="secondary">
          <div style={{height: 60}}></div>
          <Container>
            <h1 style={{textAlign: 'center'}}>Our Approach</h1>
            <br />
            <Grid>
              <div>
                <h2>Finalizing the 3Cs - Country, College, and Course</h2>
                <p>
                  Assisting you with fact based country comparison guides, we prepare an extensive shortlist for our students keeping in mind each family’s separate priorities. Our shortlist is based on factors like, Brand Reputation, Proximity to Entrepreneurial Hub, Alumni Network, Availability of Internships, Weather and many more.
                </p>
              </div>
              <Art>
                <Img fluid={data.art_learn.childImageSharp.fluid} />
              </Art>
            </Grid>
            <Grid inverse>
              <Art>
                <Img fluid={data.art_fast.childImageSharp.fluid} />
              </Art>
              <div>
                <h2>Personalised Profile Assessment and Building</h2>
                <p>
                  Our Profile Assessment Questionnaire spans and records every dimension of the student’s profile, helping us identify their strengths and gaps. With our personalized Profile Building Sessions, we aim to plug these gaps, enhance your strengths, and orient your profile to stand out. Our insights on the deal breakers, prepares you further.
                </p>
              </div>
            </Grid>
            <Grid>
              <div>
                <h2>Formulating Campus Specific Application Strategies</h2>
                <p>
                  Every campus is different with mercurial admission statistics. Application strategies are formulated specific to individual profiles. With the campus specific insights through our international network spanning across 4 continents, we exactly know what will get you into your dream school.
                </p>
              </div>
              <Art>
                <Img fluid={data.art_ideas.childImageSharp.fluid} />
              </Art>
            </Grid>
            <Grid inverse>
              <Art>
                <Img fluid={data.art_team.childImageSharp.fluid} />
              </Art>
              <div>
                <h2>Counting Offers and Picking the Best!!</h2>
                <p>
                  Every student of ours has more than one offer to choose from. More than 50% of our applicants get financial assistance. The decision of choosing the offer and evaluating the best school may seem daunting at first. Don’t worry! Bank upon us for suggestions based facts and figures. Moreover, we’ll orient you with the cross cultural differences you may come across.
                </p>
              </div>
            </Grid>
          </Container>
        </Section>
      </div>
    )}
  />
);

const Grid = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 40px;
  text-align: right;
  align-items: center;
  justify-items: center;
  margin: 24px 0;

  ${props =>
    props.inverse &&
    `
    text-align: left;
    grid-template-columns: 2fr 3fr;
  `}

  h2 {
    margin-bottom: 16px;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: left;
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 24px;
    }

    ${props =>
      props.inverse &&
      `
        ${Art} {
          order: 2;
        }
    `}
  }
`;

const Art = styled.figure`
  margin: 0;
  max-width: 380px;
  width: 100%;
`;

export default About;
